.price-subscription{
  display: flex;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
}

.subscription-price-column{
  @include breakpoint(small down) {
    background-color: $light-blue;
  }
}

.subscription-total-price{
  min-height: 427px;
  @include breakpoint(medium only) {
    min-height: 613px;
  }
}

.subscriptions-price{
  align-items: center;
}

.change-sub {
  align-items: center;
  margin-top: 30px;
}

.subscription-description{
  min-height: 427px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint(medium only) {
    min-height: 454px;
  }
}

.subscriptions-container{
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid $line-color;
}