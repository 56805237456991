#checkout-wrapper {
  /*padding-top: 30px; */

  h2 {
    @include breakpoint(small down) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.checkout-step-title, .checkout-step-title-next, .checkout-step-title-done {
  border: 1px solid $gray;
  padding: 8px 14px 7px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: $primary-font;
  letter-spacing: 0.05em;
  margin: 12px 0;
  font-size: 14px;

  .index {
    border: 1px solid $black;
    border-radius: 50%;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    &, * {
      text-align: center;
      color: inherit;
      font-size: inherit;
    }
  }
}

.checkout-step-title {
  color: $blue;
  background-color: white;

  .index {
    border-color: $blue;
  }
}

.checkout-step-title-next {
  opacity: .3;
}

.cart-summary-container {
  background-color: $light-blue;
  padding: 0;

    .checkout-step-title {
      background-color: $light-blue;
      border-color: $light-blue;
    }

    .cart-summary {
      padding: 0 20px 14px;
      font-size: 14px;
    }
}

#billing-form-btn {
  @include breakpoint(small down) { margin-bottom: 10px; }
}

#checkout-done { padding-top: 40px; }

.additional-fee {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin: -7px 0 12px;
}

.purchase-cancelled { padding-top: 40px; }

.container-paypal-additional-cost {
  display: flex;
  align-items: center;
  & span {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
}