.login-subscribe-container { padding-top: 40px; }

#password-recover-form { display: none; }

#subscribed-container { padding-top: 40px; }

.login-separator{
    margin: 8px 0px;
    border-top: 1px solid $line-color;
    width: 100%;
}
.img-lista{
    width: 24px;
}

.subscribe-list{
    margin-top: 10px;
    font-size: 18px;
}

.notes {
    font-size: 14px;
    line-height: 24px;
}

.select-prefix {
    @media screen and (min-width: 40em) {
        padding-left: 0px;
    }
}

.fill-country-prefix {
    @media screen and (max-width: 39.9em) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

.image-container {
    padding-right: 0px;
    border: 1px solid $gray;
    border-right: 0px;
    margin-bottom: 10px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}

.image-column {
    padding-right: 0px;
    margin-right: -5px;
}