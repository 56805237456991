// top banner
.top-banner {
    font-size: 12px;
    padding: 6px 32px;
    @include breakpoint(small down) {
        min-height: 48px;
        @include flex-center;
    }
}

.close-top-banner {
    @include basic-transition;
    position: absolute;
    right: 8px;
    width: 15px;
    top: 7px;
    cursor: pointer;
    @include breakpoint(small down) {
        top: 15px;
    }

    &:hover {
        opacity: 0.7;
    }
}

// header
header {
    //display: flex;
    padding: 30px 0 10px 0;
    z-index: $header-z;
    @include breakpoint(medium down) {
        padding: 15px 0;
        border-bottom: 1px solid $black;
    }
}

.header-logo {
    max-width: 180px;
    width: 100%;
    margin-top: 7px;
    @include breakpoint(small down) {
        display: none;
    }
}

.header-main__logo {
    width: 100%;
}

.header-search {
    height: 30px;
}

.header-logo-mobile {
    display: none;
    padding-top: 8px;
    margin: 0 auto;
    width: 100%;
    @include breakpoint(small down) {
        display: block;
    }
}

.mobilemenu-item {
    border-bottom: 1px solid $line-color;
    display: flex !important;
    justify-content: space-between;
}

.navigation {
    z-index: $header-z;
    position: absolute;
    width: 100%;
    top: 121px;
    @include breakpoint(medium down) {
        width: 270px;
        height: 100%;
        position: fixed;
        top: 0;
        left: -270px;
        background: $light-blue;
        font-size: 0.8em;
        font-family: sans-serif;
        font-weight: 300;
        transition: all 0.3s ease;
        border-bottom: none;
        padding: 35px 37px 48px;
    }

    .navigation-menu {
        background-color: white;
        @media screen and (min-width: 40em) {
            padding: 10px 12px 8px 20px;
        }
        @include breakpoint(medium down) {
            background-color: transparent;
            padding-top: 0;
            border-bottom: none;
        }
    }

    .sticky-cart-icon {
        position: relative;
        float: right;
        top: -5px;
        display: none;

        .cart-icon {
            width: 20px;
        }
    }

    &.top-banner-closed {
        top: 90px;
        @include breakpoint(medium down) {
            top: 0;
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        border-bottom: 1px solid $line-color;

        .navigation-menu {
            padding: 26px 34px 2px 20px !important;
        }

        .submenu-container {
            top: 56px;
        }

        .float-right {
            float: none;
            position: relative;
            left: -4px;
        }

        .main-menu-item.search {
            @include breakpoint(medium up) {
                margin-right: 80px;
            }
        }

        .main-menu-item:last-child > a {
            padding-right: 10px;
        }

        .sticky-cart-icon {
            display: block;
        }
    }
}

.navigation-banner {
    text-align: center;
    background-color: rgba(254, 254, 254, 0.9);
    padding: 6px 0 8px;

    span {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: $blue;
    }
}

.subscribe-link-fixed {
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    position: relative;
    top: 4px;
    letter-spacing: 0.03em;
    @media only screen and (max-width: 1200px) {
        display: none;
    }

    &.fixed-btn {
        margin: 0 16px 0 12px;
        background-color: $light-blue;
        padding: 0 5px;
    }
}

.main-menu {
    display: flex;
    justify-content: space-between;
}

.main-menu-item {
    display: inline-block;
    @include breakpoint(medium down) {
        display: block;
        float: none;
    }

    > a {
        font-family: $primary-font;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.05em;
        padding: 2px 18px 5px;
        position: relative;
        display: inline-block;
        @include breakpoint(medium down) {
            display: block;
            padding: 12px 0 10px;
            border-bottom: 1px solid $light-blue;
        }

        &:after {
            content: "";
            position: absolute;
            background-color: $blue;
            left: 0;
            right: 0;
            height: 0;
            bottom: 0px;
            transition: all 0.1s;
        }

        &:hover:after {
            height: 2px;
            @include breakpoint(medium down) {
                height: 0;
            }
        }
    }

    .diff-color-item {
        &.orange {
            color: $light-blue;
            &:after {
                background-color: $light-blue;
            }
        }

        &.pink {
            color: $blue;
            &:after {
                background-color: $blue;
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 3px;
            bottom: -3px;
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    &:hover {
        .submenu-container {
            display: block;
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }
}

.mobile-logout {
    background: none;
    border: none;
    font-family: $primary-font;
    font-size: 14px;
    text-transform: uppercase;
    display: block;
    padding: 14px 0;
}

.submenu-container {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    top: 37px;
    max-width: 1207px;
    height: 420px;
    border-bottom: 1px solid $line-color;
    border-top: 1px solid $line-color;
    border-right: 1px solid $line-color;
    border-left: 1px solid $line-color;
    margin: 0 auto;
    @include breakpoint(medium down) {
        position: static;
        border: none;
        background: none;
    }
}

.main-menu-item.relative .submenu-container {
    top: 31px;
    width: 200px;
}

.submenu {
    display: flex;
    @include breakpoint(medium down) {
        display: block;
        padding: 10px 0 20px;
    }

    > div {
        flex: 1;
        padding: 25px 23px 14px;
        @include breakpoint(medium down) {
            padding: 0 15px;
        }
    }

    a {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #2f2d32;
        text-transform: capitalize;
        @include basic-transition;

        &:hover {
            color: $blue;
        }
    }

    h4,
    h4 a {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        color: #2f2d32;
    }

    .menu-image {
        @include background-center;

        a {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.language-dropdown,
.currency-dropdown {
    .flag-image {
        width: 15px;
        position: relative;
        top: -2px;
        margin-right: 7px;
    }

    .sort-image {
        margin-left: 5px;
    }

    ul {
        width: max-content;
    }

    [data-currency] {
        display: flex;
        width: 100%;

        .currency-code {
            margin-left: auto;
        }
    }
}

.header-icon {
    z-index: $header-z;
    position: relative;
    display: inline-block;
    padding-left: 20px;
    @include basic-transition;

    span {
        display: block;
        font-size: 11px;
        white-space: nowrap;

        span {
            display: inline;
        }
    }

    .search-icon {
        width: 22px;
    }

    .profile-icon {
        width: 21px;
        padding-bottom: 1px;
    }

    .cart-icon {
        width: 20px;
    }

    &:not(.cart):hover {
        opacity: 0.7;
    }
}

.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 3px 0px;
    border: 1px solid white;

    ul {
        display: none;
        position: absolute;
        z-index: $header-z + 1;
        background-color: white;
        border: 1px solid $black;
        left: -6px;
        top: 30px;
        padding: 6px 12px 7px;
        border-radius: 5px;

        a:hover {
            color: $blue;
        }
    }

    &:hover {
        ul {
            display: block;
        }
    }
}

.profile-dropdown {
    padding: 3px 3px 0;
    margin: 0 -3px 0 20px;
    z-index: $header-z + 1;

    img {
        width: 20px;
    }

    span {
        display: block;
        font-size: 11px;
    }

    ul {
        top: 46px;
        width: 97px;
    }

    input {
        border: none;
        cursor: pointer;

        &:hover {
            color: $blue;
        }
    }
}

// search modal
#search-modal {
    display: none;
    position: absolute;
    z-index: $messagebox-z - 1;
    background-color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.search-open {
        @include flex-center;
    }
}

#mainsearch {
    background: $light-gray;
    border: none;
    box-shadow: none;
    font-size: 16px;
    height: 26px;
    margin: 0;
    width: 100%;
    padding-left: 30px;
}

#mainsearch-mobile {
    background: $light-gray;
    border: none;
    box-shadow: none;
    font-size: 16px;
    height: 26px;
    margin: 0;
    width: 100%;
    padding: 15px 30px;
    /*padding-left: 30px; */
}

.search-icon {
    position: absolute;
    top: 4px;
    width: 20px;
    left: 4px;
}

.search-form {
    width: 100%;
}

.close-search-modal {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 16px;
    cursor: pointer;
    @include basic-transition;

    &:hover {
        opacity: 0.7;
    }
}

#search-trigger {
    cursor: pointer;
}

// mobile menu
.nav-toggle {
    cursor: pointer;
    padding: 10px 35px 16px 0px;
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 2px;
    width: 28px;
    background: #343434;
    position: absolute;
    display: block;
    content: "";
}

.nav-toggle span:before {
    top: -10px;
}

.nav-toggle span:after {
    bottom: -10px;
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
    transition: all 200ms ease-in-out;
}

.nav-toggle.active span {
    background-color: transparent;
}

.nav-toggle.active span:before,
.nav-toggle.active span:after {
    top: 0;
}

.nav-toggle.active span:before {
    transform: rotate(45deg);
}

.nav-toggle.active span:after {
    transform: rotate(-45deg);
}

#header-link {
    @include breakpoint(small down) {
        margin-bottom: 18px;
    }
}

.position {
    position: absolute;
    top: 5px;
    left: 10px;
    transition: all 0.3s ease;
    z-index: 2;
    @include breakpoint(small down) {
        top: 0px;
    }

    &.top-banner-closed {
        top: 33px;
        @include breakpoint(small down) {
            top: 25px;
        }
    }
}

.position-search {
    top: 64px;
}

.main {
    width: 100%;
    position: absolute;
    left: 0;
    transition: 0.3s ease all;
}

.show-nav .navigation,
.show-nav .main {
    transform: translateX(270px);
}

.show-nav .navigation {
    overflow-y: auto;
}

.search-mobile {
    #mainsearch {
        margin-bottom: 0;
        text-align: left;
        font-size: 16px;
        background-color: white;
        border: 1px solid $light-blue;
        border-radius: 4px;
    }
}

.mobile-languages {
    margin: 18px 0 60px;

    ul {
        display: flex;
        justify-content: space-between;
    }

    img {
        width: 30px;
        opacity: 0.5;
    }

    .mobile-active {
        border-bottom: 3px solid $light-blue;
        padding-bottom: 6px;

        img {
            opacity: 1;
        }
    }
}

/* tasto esci */

.logout-menu {
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 0 10px;
}

//menu

.first-item-img {
    width: 100%;
    height: 61%;
    margin-top: 15px;
}

ul.a_z-list {
    li {
        width: 20%;
        /*float: left; */
        display: inline-flex;
    }
}

.designer-list {
    li {
        width: 25%;
        float: left;
        margin-top: 10px;
    }
}

.menu-all-button {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    a {
        text-transform: uppercase;
    }
}

.anchor-menu {
    position: absolute;
    left: 0px;
    top: -60px;
}

.header-sticky {
    @include breakpoint(medium down) {
        position: sticky;
        top: 0;
        background: white;
    }
}

#cart-icon {
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    transform-style: preserve-3d;
    padding: 0 8px;

    &.sticky {
        @include breakpoint(medium up) {
            position: fixed;
            top: 14px;
            right: 30px;
            background-color: $white;

            > a {
                display: flex;
                align-items: center;
                height: 40px;

                > span {
                    color: $white;
                    font-size: 0;
                }

                .count {
                    font-size: 14px;
                }
            }
        }
    }

    &.open {
        border-color: $black;

        &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            border-top: 1px solid $white;
        }

        .popup {
            display: block;
        }
    }

    .link-to-cart {
        display: none;
    }

    .popup {
        cursor: default;
        display: none;
        position: absolute;
        width: 450px;
        padding: 16px;
        right: -1px;
        border: 1px solid $black;
        background-color: $white;
        transform: translateZ(-1px);

        .close-popup {
            display: none;
            cursor: pointer;
            position: absolute;
            top: 8px;
            right: 16px;

            @include breakpoint(small down) {
                display: block;
            }
        }

        .items {
            width: 100%;
            max-height: 400px;

            &.overflow {
                overflow-x: hidden;
                overflow-y: scroll;
                width: calc(100% + 16px);
                padding-right: 16px;
            }
        }

        .controls {
            width: 100%;
            padding-top: 16px;
        }

        .items,
        .controls {
            .container {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
            }
        }

        .title {
            font-weight: bold;
        }

        hr {
            margin: 8px 0;
        }

        .product-image {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }
            &.inline-block {
                border: 1px solid $border;
            }
        }

        .product-brand,
        .product-name,
        .product-size,
        .product-quantity {
            &,
            span,
            a,
            p {
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .product-size,
        .product-quantity {
            text-transform: capitalize;
        }

        .product-price,
        .product-price-discounted {
            &,
            span,
            a,
            p {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .product-price.discount {
            &,
            .euro,
            span {
                color: $dark-orange;
            }
        }

        .product-price-discounted {
            &,
            .euro,
            span {
                color: $gray;
                text-decoration: line-through;
            }
        }

        .product-remove {
            color: $gray;
            font-size: 14px;
            line-height: 24px;
        }

        .shipping-title {
            &,
            span,
            a,
            p {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .total-title {
            &,
            span,
            a,
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .shipping-price,
        .total-price-eur {
            &,
            span,
            a,
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .total-price {
            &,
            span,
            a,
            p {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .total-title,
        .total-price {
            font-weight: bold;
        }
    }
}

#cart-icon.open {
    @include breakpoint(small down) {
        border-color: transparent;
        // position: fixed;
        // top: 0;
        // left: 0;
        // right: 0;
        // z-index: $messagebox-z - 1;

        .popup {
            display: none;
            // padding-top: 48px;
            // top: 0;
            // left: 0;
            // right: 0;
            // width: 100%;
            // transform: none;
        }
    }
}
