footer {
  margin-top: 50px;

    a {
      @include basic-transition;

      &:hover { color: $blue; }
    }
}

.left-border {
  border-left: 1px solid $line-color;
}

.footer-top {
  background-color: $gray;
  padding: 8px 0;
  @include breakpoint(small down) { padding: 12px 0; }

    img { width: 19px; }

    span {
      font-size: 14px;
      margin-left: 8px;
    }

    > div > div {
      @include breakpoint(small down) {
        flex-direction: column;
        text-align: center;
      }
    }
}


.footer-center {
  background-color: $light-blue;
  padding: 40px 0px 10px 0px;

    .link-footer {
      font-size: 14px;
      margin-top: 7px;
    }

    h4 {
      font-size: 16px;
    }
    .subtitle-img {
      font-weight: 600;
    }
}


.footer-social {
 /* text-align: center;
  padding: 10px 0 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; */
    a {
      display: inline-block;
      margin: 10px;
    }

}


.footer-bottom {
  padding: 14px;
  background: $light-blue;
  display: flex;
  flex-direction: column;
  align-items: center;

    &.pre {
      flex-direction: row;
      justify-content: center;
      /*margin: 26px 0;*/
      flex-flow: row wrap;
      background-color: $white;
    }

    .footer-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.02em;
    }

    .col-2-vertical {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      font-size: 11px;
      display: inline-block;
      @include breakpoint(small down) { padding-top: 10px; }
    }
    a{
      font-size: 12px;
      padding-top: 7px;
      display: inline-block;
      @include breakpoint(small down) { padding-top: 10px; }
    }

    .footer-bottom-box {
      text-align: center;
      @include breakpoint(small down) { width: auto; }
    }

    .flex-container {
      @include breakpoint(small down) {
        flex-direction: column;
        text-align: center;
      }
    }

    .txt-right {
      @include breakpoint(small down) {
        text-align: center;
        margin-top: 10px;
      }

        img { width: 250px; }
    }

    .logo-zero11 {
      width: 60px;
      margin-left: 6px;
      @include basic-transition;

        &:hover { opacity: .7; }
    }

    .logo-rewix {
      width: 62px;
      margin-left: 8px;
      @include basic-transition;

        &:hover { opacity: .7; }
    }
}


#subscribe-newsletter {

    #newsletter-arrow {
      background-color: $blue;
      color: $white;
      line-height: normal;
      height: 2.4375rem;
      width: 62px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include basic-transition;
      border-radius: 5px;

        &:hover { background-color: $blue-hover; }

        img { width: 23px; }
    }

    button { font-size: 13px; }

    > .label-input { margin-top: 0px; }

    #newsletter-step-2 { display: none; }
}

#newsletter-success {
  display: none;

    span {
      display: block;
      text-transform: uppercase;
      background-color: #bbf7bb;
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: green;
      border: 1px solid green;
      padding: 3px 0;
    }
}

.payment-icons{
  width: 300px;
}

.delivery-img {
  width: 300px;
}

.img-payments {
    width: 100%;
}