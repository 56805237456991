* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary-font;
  color: $black;
  list-style-type: none;
  outline: 0 !important;
}

body {
  overflow-x: hidden;

    &.show-nav { overflow-y: hidden; }
}

a { text-decoration: none; }

.wrapper {
  margin-top: 74px;
  min-height: calc(100vh - 550px);
  @include breakpoint(medium down) {
    margin-top: 0;
  }
}

.row {
  max-width: 90rem;
}

.relative { position: relative; }

.list-unstyled { list-style: none; }

.width-100 { width: 100%; }
.height-100 { height: 100%; }

.float-right { float: right; }

.block { display: block; }

.flex { display: flex; flex-flow: row wrap;}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-block { display: inline-block; }

.slick-slide img.link-arrow { display: inline-block; }

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

// border
.border-bottom-grey{ border-bottom: 1px solid $gray; }

// backgrounds
.bg-light-blue { background-color: $light-blue; }
.bg-light-blue-dark { background-color: $light-blue-hover; }

// spacing
.padding-20 {padding:20px;}
.padding-30 {padding:30px;}
.padding-left-right-0{padding: 17px 0px;}
.padding-left-20{padding-left: 20px;}
.padding-top-40{ padding-top: 40px;}
.margin-0 { margin: 0; }
.margin-20-0{ margin: 20px 0};
.margin-top-5{ margin-top: -5px}
.margin-top-10 { margin-top: 10px; }
.margin-top-15 { margin-top: 15px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-30 { margin-top: 30px;}
.margin-top-50 { margin-top:50px;}
.padding-top-20 { padding-top: 20px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-50 { margin-top: 50px; }
.margin-bottom-3 { margin-bottom: 3px; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-bottom-15 { margin-bottom: 15px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-bottom-40 { margin-bottom: 40px; }
.margin-bottom-50 { margin-bottom: 50px; }
.margin-right-15 { margin-right: 15px; }
.margin-right-20 { margin-right: 20px; }
.margin-left-10 { margin-left: 10px; }
.margin-30-vert {
  margin: 30px 0;
  @include breakpoint(small down) { margin: 30px 0; }
}

.margin-top-mobile-10 {
  @include breakpoint(small down) { margin-top: 10px; }
} 

.margin-mobile-20 {
  @include breakpoint(small down) { margin-bottom: 20px; }
}

.margin-top-mobile-20 {
  @include breakpoint(small down) { margin-top: 20px; }
}

.margin-top-mobile-30 {
  @include breakpoint(small down) { margin-top: 30px; }
}

.margin-mobile-40 {
  @include breakpoint(small down) { margin-bottom: 40px; }
}

.margin-top-small-medium-20 {
  @include breakpoint(medium down) { margin-top: 20px; }
}

.margin-right-10{ margin-right:10px;}
.margin-20{
  margin: 20px 0px;
}
//sections
.padding-section-50{
  padding: 50px 0px;
}

// mixins
@mixin basic-transition { transition: all .15s; }

@mixin background-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#button {
  display: inline-block;
  background-color: $blue;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  bottom: 50px;
  left: 30px;
  transition: background-color .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  img {
    transform: rotate(180deg);
    margin-top: 7px;
  }
}
#button:hover {
  cursor: pointer;
  background-color: #333;
}
#button:active {
  background-color: #555;
}
#button.show {
  opacity: 1;
  visibility: visible;
}

#whatsapp {
  cursor: pointer;
  position: fixed;
  bottom: 50px;
  right: 30px;
  opacity: .4;
  z-index: $messagebox-z - 2;

  &:hover {
    opacity: 1;
  }

  @include breakpoint (small down) {
    opacity: 1;
  }
}

.left-arrow {
  color: $blue;
  padding-right: 5px;
}

.coming-soon {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: RGB(242,242,242);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coming-soon-block {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 34px;
  text-align: center;
  -webkit-box-shadow: 11px 11px 29px -2px rgba(0,0,0,0.74);
  -moz-box-shadow: 11px 11px 29px -2px rgba(0,0,0,0.74);
  box-shadow: 11px 11px 29px -2px rgba(0,0,0,0.74);
}

.bg-light-blue{
  background-color: $light-blue;
}
// chat
div#lhc_status_container {
  z-index: 1;
  @include breakpoint(small down) { display: none !important; }
}

.lds-dual-ring {
  display: inline-block;
  background: white;
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  height: 100%;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $blue;
  border-color: $blue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.back-icon {
  width: 6px;
  margin-top: -3px;
  margin-right: 7px;
}

.hr-blue{
  border-color: $blue;
}

hr {
  border: 0;
  border-top: 1px solid $line-color;
}

.currency-code {
  color: $gray;
}

.anchor {
    color: $blue;
    text-decoration: underline;

    &:visited {
        color: $blue-hover;
    }
}

.accordion {
  background-color: transparent;

  .accordion-item {
    &.is-active {
      .accordion-title {
        color: $blue; 
        font-weight: 600;
      }
    }
  }

  .accordion-title {
    font-size: 16px;
    line-height: 24px;
    color: $black;
    font-weight: 600;
  }

  .accordion-content {
    p {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      margin-bottom: 0;
    }
  }
}