.profile-content {
  padding-left: 30px;
  @include breakpoint(small down) {
    padding-left: 0;
    margin-top: 25px;
  }

    hr {
      border: none;
      border-top: 1px solid $line-color;
      margin-top: 25px;
    }
}


.table-orders {
  border-collapse: collapse;

    th {
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      padding-bottom:5px;
    }
    tr.order-controls{
      background-color: $white;
    }

    &tbody tr:nth-child(2n){
      border-bottom: 0;
      background-color:$white;
    }

    & thead{
      border: none;
      &.row-article{
        background-color: $white;
      }
    }
    & tbody{
      border:none;
    }
}
/*
.table-orders tbody tr:nth-child(2n) {
  border-bottom: 0;
  background-color: white;
}
*/

.order-row {
  cursor: pointer;
  @include basic-transition;

    &:hover { background-color: $light-blue; }

    td {
      padding: 5px 8px;
      font-size:14px;
      border-top: 1px solid $line-color;
      border-bottom: 1px solid $line-color;

      &:first-child { width: 21px; }
    }
}

.order-row-indicator {
  @include basic-transition;
  position: relative;
  top: -1px;
  width: 100%;

    &.fa-rotate-90 {
      transform: rotate(90deg);
    }
}

.order-controls {
  display: none;
  border-left: 1px solid $blue;

    > td {
      padding: 12px 20px;
    }
    
}

.dl-horizontal {

  dt {
    width: 40%;
    float: left;
    clear: left;
  }

  dd {
    width: 60%;
    float: left;
    margin: 0;
    text-align: right;
  }
}

.order-controls-return {
  border-collapse: collapse;
  border-top: 1px solid $line-color;
    td {
      border-top: 1px solid $line-color;
      padding: 3px 8px;
      line-height: 1.43;
      vertical-align: middle;
    }

    tbody tr{
      background-color:$white;
    }
}

.btn-download {
  padding: 4px 8px;
  font-size: 13px;
  letter-spacing: 0;
  margin: 2px;
}

.new-return-request { display: none; }

.table-orders { min-width: 650px; }

.responsive-table { overflow-x: auto; }


// profile consent
.profile-privacy-row {
    h4 {
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 5px;
      color: $blue;
    }
}

.profile-privacy-col-inputs {
  display: flex;
  justify-content: space-between;
  @include breakpoint(small down) {
    flex-direction: column;
  }
}

.profile-nav{
  color:$black;
  font-size:14px;
  a{
    transition: all .15s ease-in-out;
    display: block;
    letter-spacing: .1em;
    margin-bottom: 0;
    text-transform: uppercase;
    background-color: $white;
    padding: 10px 6px;
    &.active, &:hover{
      background-color: $light-blue;
      color: $blue;
    }
  }
}
ul.list-unstyled.profile-nav li.active{
  background-color: $light-blue;
  color: $blue;
  padding: 10px 6px;
}
input:disabled, input[readonly], textarea:disabled, textarea[readonly]{
  background-color: $light-gray;
}

.my-orders{
  padding-bottom: 14px;
}

//profile subscription
.subscription-type-title{
  display: flex;
  align-items: center;
}

.password-eye {
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 9px;
  width: 22px;
  height: 22px;
  background-color: $black;
  mask: url('/skins/current-skin/images/icons/eye.svg');

  &.active {
    background-color: $blue;
  }
}