#product-container {
    padding-top: 40px;
    @include breakpoint(small down) {
        padding-top: 10px;
    }

    img.active {
        border-bottom: 1px solid $blue;
    }
}

.product-information {
    margin-left: 50px;
    @include breakpoint(medium down) {
        margin-left: 10px;
    }
    @include breakpoint(small down) {
        margin-left: 0;
    }

    .prices {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        .taxable-price {
            font-size: 30px;
            font-weight: bold;
        }

        .retail-price {
            margin-left: 20px;
            @include breakpoint(small down) {
                margin-left: 5px;
            }

            .price-label {
                display: block;
                font-size: 10px;
                font-weight: lighter;
                text-transform: uppercase;
                line-height: 10px;
            }
        }
    }
}

.rp-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line-through {
    font-size: 18px;
    text-decoration: line-through;
    color: $gray;
    span {
        color: $gray;
    }
    &.taxable-price {
        font-size: 16px;
        margin-right: 4px;
    }
}

.retail-price .line-through span {
    color: $gray;
}

#product-slider {
    @include breakpoint(small down) {
        margin-bottom: 20px;
    }
}

#product-slider-nav {
    display: flex;
    flex-direction: column;

    img {
        border: 1px solid $border;
        margin: 4px 0;
        &:first-child {
            margin-top: 0;
        }
    }
}

.add-to-cart-button {
    margin-top: 5px;
}

.related-title {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.02em;
    color: $blue;
    margin: 50px 0 5px;
}

.product-slide-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.arrow-right {
        right: -10px;
    }
    &.arrow-left {
        left: -10px;
    }
}

.product-name {
    line-height: normal;
    text-transform: uppercase;
}

.product-sku {
    margin-top: 10px;
    @include breakpoint(small down) {
        font-size: 14px;
    }
}

table.table-sizes {
    border-collapse: collapse;
    & thead {
        background-color: $white;
        border: none;
    }
}

.table-body {
    border: none;
    & tr:nth-child(2n) {
        background-color: $white;
        border: none;
    }
}

.sizes-table {
    background-color: $light-blue;
    display: inline-block;
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 12px;
    border-radius: 20px;
    @include basic-transition;

    &:hover {
        background-color: $light-blue-hover;
    }
}

.list-tag {
    margin-top: 30px;

    li {
        display: inline-block;
        margin-bottom: 3px;
    }
}

.table-sizes {
    th {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
    }

    td {
        text-align: center;
        padding: 6px 9px;
        width: 33%;
    }
    > tbody > tr:nth-child(odd) {
        background: $light-gray;
    }
}

.product-separator {
    margin: 30px 0 20px;
    border: none;
    border-top: 1px solid $gray;
}

.product-tabs {
    display: flex;
    justify-content: space-between;

    .tab {
        flex: 1;
        text-align: center;
        border-bottom: 1px solid $gray;
        padding: 18px 0 16px;
        text-transform: uppercase;
        font-family: $primary-font;
        font-weight: 600;
        cursor: pointer;
        @include breakpoint(small down) {
            padding: 12px 0 10px;
            font-size: 12px;
        }

        &.active {
            border-bottom: 2px solid $blue;
        }
    }
}

.tab-container {
    display: none;
    margin-top: 30px;

    &.active {
        display: block;
    }
}

.tab-shipping {
    h3 {
        margin-top: 20px;
    }

    p {
        margin-bottom: 10px;
    }
}

#related-slider .product-container .grid-size-list {
    display: none;
}

.best-price-div {
    display: flex;
    flex-direction: column;
}

.discount-p {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 600;
}

.title-18 {
    font-size: 18px;
    color: $blue;
    margin-bottom: 10px;
}

.product-tabs-wrapper {
    background-color: $light-blue;
    padding-bottom: 25px;
}

.detail {
    &.-right {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-weight: 600;
    }
}

.euro {
    color: $black;
    &.retail {
        color: $gray;
    }
}

.i-number-btn {
    border: 1px solid $black;
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    background-color: $white;
}

.qty-input-container {
    display: flex;
    align-items: initial;
    justify-content: center;
    position: relative;
}

.i-number {
    margin: 0;
    height: 35px;
    box-shadow: none;
    width: 50px;
    text-align: center;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    border-left: none;
    border-right: none;
    background-color: $white;
    cursor: default;
}

.qty-table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    th {
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.05em;
        padding-bottom: 5px;
        text-align: center;
    }

    td {
        border-top: 1px solid $light-gray;
        /* border-bottom: 1px solid $line-color; */
        padding: 6px 0 15px;
    }

    .availability {
        opacity: 0.4;
    }
}

.qty-max {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid $black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1px;
    margin-left: 8px;
    position: relative;

    &:active {
        top: 1px;
    }
}

.wishlist-table-size-icon {
    padding: 3px 9px 6px 9px;
}

.product-img {
    border: 1px solid $border;
    min-height: 261px;
    width: 100%;
    overflow: hidden;

    @include breakpoint(medium only) {
        min-height: 214px;
    }
    @include breakpoint(small down) {
        min-height: 156px;
    }
}

.box-img {
    height: 100%;
    border: 1px solid $border;
    overflow: hidden;

    @include breakpoint(small down) {
        border-left: 0;
        border-right: 0;
    }
}

//bollino promo
.stiker-promo {
    width: 48px;
    height: 48px;
    background-color: $orange;
    position: absolute;
    z-index: 10;
    border-radius: 50px;
    right: 1rem;
    top: 1.5rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    &.black {
        background-color: $black;
    }

    &.special-xmas {
        background-color: #bb141b;
    }

    &.saldi90 {
        background-color: #004a85;
    }

    &.saldi {
        background-color: $saldi;
    }

    &.list {
        right: 2.2rem;
        top: 2.5rem;
    }

    &.grid {
        right: 10px;
        top: 0.5rem;
    }
}

.text-promo {
    color: $dark-orange;
    font-size: 17px;
    font-weight: 600;

    &.white {
        color: $white;
    }
}

//label
.sticker-promo-label {
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 0px;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-justify-content: center;

    &.catalog {
        right: 0px;
        bottom: 0px;
    }

    &.list {
        bottom: 0px;
        right: 16px;
    }
}

.prices-box {
    border-top: 1px solid $border;
    padding-top: 10px;
}

.availability-sticker {
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    top: 15%;
    left: -30%;
    transform: rotate(-45deg);
    color: $black;
    background: $light-blue-hover;
    width: 100%;
    text-align: center;
    z-index: 4;

    @include breakpoint(small down) {
        font-size: 10px;
    }
}

.product-container.list {
    .availability-sticker {
        top: 10%;
        left: -40%;

        @include breakpoint(medium only) {
            top: 16%;
            left: -33%;
        }
    }
}

.box-img {
    .availability-sticker {
        top: 10%;
        left: -38%;

        @include breakpoint(medium only) {
            top: 13%;
            left: -34%;
        }
    }
}