.alert-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;

    .alert {
      padding: 20px;
      font-family: $primary-font;
      text-transform: uppercase;
      color: white;
      animation-name: alert;
      animation-duration: 200ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    .alert-danger { background-color: $red; }

    .alert-success { background-color: $green }
}

@keyframes alert {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}