$size-list-width: 100px;

.container-catalog {
	padding-top: 10px;
	@include breakpoint(small down) {
		padding-top: 20px;
	}
}

.filter-dropdown > div > ul > li:nth-child(2) {
	margin-top: 10px;
}

.btn-catalog {
	width: 100%;
	border-radius: 0 !important;
}

.catalog-product.detail {
    .product-container {
        .product-category {
            margin-top: 12px;
        }

		.price {
            margin-top: 20px;
			font-size: 22px;
			line-height: 32px;
		}
    }
}

.product-container {
	.product-link {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 10;
	}

	.price {
		margin: 0 2px;
		font-weight: 600;
		font-size: 22px;
		line-height: 24px;
		@include breakpoint(medium down) {
			font-size: 18px;
		}
		.line-through {
			text-decoration: line-through;
			color: #959595;

			span {
				color: #959595;
			}
		}
	}

	.product-id {
		margin-top: 12px;
	}

	.product-category {
		text-transform: capitalize;
		letter-spacing: 0.02em;
		font-size: 14px;
		line-height: 24px;
        margin-top: 12px;

		@include breakpoint(medium down) {
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 8px;
		}
	}

	.product-name {
		font-size: 16px;
		line-height: 24px;
		margin: 4px 0 10px;
		justify-content: flex-start;

		@include breakpoint(small down) {
			font-size: 14px;
			line-height: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			height: auto;
		}
		&.center {
			justify-content: center;
		}

		&.list {
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;

			@include breakpoint(medium down) {
				font-size: 14px;
				line-height: 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
				height: auto;
			}
		}
	}

	.grid-size-list {
		position: absolute;
		bottom: 0px;
		right: 0px;
		opacity: 0;
		width: 0px;
		background-color: white;
		z-index: 5;
		text-align: center;
		transition: 0.3s;

		li {
			margin-bottom: -1px;
			font-size: 14px;
			padding: 2px 0;
		}
	}

	.grid-size-list > li:nth-child(odd) {
		background: #f4f4f4;
	}

	img + img,
	picture + picture {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 1;
	}

	&:hover {
		.grid-size-list {
			opacity: 1;
			width: 65px;
			font-weight: 600;
		}

		img + img,
		picture + picture {
			opacity: 1;
		}
	}
}

.filter-title {
	font-size: 16px;
	display: block;
	position: relative;
	padding: 10px 0;
	cursor: pointer;

	&::after {
		background-image: url(/skins/current-skin/images/filter-plus.svg);
		right: 0;
		position: absolute;
		top: 16px;
		background-size: 12px 12px;
		display: inline-block;
		width: 12px;
		height: 12px;
		content: "";
	}

	&.filter-open::after {
		background-image: url(/skins/current-skin/images/filter-minus.svg);
	}
}

.panel-group {
	border-bottom: 1px solid $black;

	.panel-collapse {
		display: none;
	}
}

.catalog-banner {
	h1 {
		padding: 12px 0;
		@include breakpoint(small down) {
			font-size: 26px;
		}
	}

	.catalog-banner-img {
		height: 300px;
		margin-bottom: 5px;
		@include background-center;
		@include breakpoint(medium down) {
			height: 26vw;
		}
		@include breakpoint(small down) {
			height: 35vw;
		}
	}

	.catalog-banner-text {
		background-color: $light-gray;
		padding: 15px 25px;
		@include breakpoint(small down) {
			display: none;
		}
	}
}

.filter-bullets {
	.txt-right {
		@include breakpoint(small down) {
			display: flex;
			justify-content: space-between;
			margin-top: 15px;
		}
	}
}

// Codice vecchio
@keyframes indeterminate-bar {
	0% {
		transform: translate(-50%) scaleX(0);
	}
	50% {
		transform: translate(0%) scaleX(0.3);
	}
	100% {
		transform: translate(50%) scaleX(0);
	}
}

#layout-options,
#toggle-filters-button {
	margin-bottom: 5px;
}

#catalog-container {
	.catalog-spinner {
		display: none;
		z-index: 10000;
		position: fixed;
		top: 0;
		width: 100%;
		height: 3px;
		@include breakpoint(small down) {
			top: 0;
		}

		&:after {
			-webkit-transform-origin: center center;
			transform-origin: center center;
			-webkit-animation: indeterminate-bar 2s linear infinite;
			animation: indeterminate-bar 2s linear infinite;
			width: 100%;
			height: 2px;
			background-color: $light-blue;
			content: "";
			display: block;
		}
	}

	&.catalog-loading {
		.catalog-spinner {
			display: block;
		}

		&:before {
			content: "";
			display: block;
			position: fixed;
			z-index: 10000;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(white, 0.5);
		}
	}
}

.remove-filter {
	font-size: 12px;
	display: block;
	margin-bottom: 2px;

	img {
		margin-right: 8px;
		width: 10px;
		position: relative;
		top: -1px;
	}
}

.breadcrumb {
	li {
		display: inline-block;
	}

	a {
		display: inline-block;
		padding: 0 4px;
	}
}

.radio-layout {
	display: inline-block;

	input {
		position: absolute;
		left: -9999px;

		&:checked + label {
			background-color: $light-blue;
		}
	}

	label {
		margin: 0 2px;
	}
}

.catalog-title {
	font-weight: 600;
	font-size: 30px;
	letter-spacing: 0.02em;
	color: $blue;
	text-align: center;
	text-transform: uppercase;
	margin-top: 0px;

	&.dropshipping {
		margin-bottom: 50px;
		@include breakpoint(medium down) {
			margin-bottom: 30px;
			margin-top: 30px;
		}
	}
}

#sort-options {
	padding-bottom: 10px;

	.sort-btn {
		margin-left: 2px;
	}

	.sort-dropdown {
		display: none;
	}

	.sort-dropdown {
		width: max-content;
		position: absolute;
		z-index: 20;
		background-color: white;
		top: 35px;
		right: 0;
		border: 1px solid $black;
		text-align: left;
		border-radius: 5px;
		padding-top: 2px;

		a {
			margin-top: -1px;
			padding: 5px 10px;
			text-transform: capitalize;
			display: block;

			&:hover {
				color: $blue;
			}
		}
	}

	&:hover {
		.sort-dropdown {
			display: block;
		}
	}
}

.pagination {
	margin: 20px 0;

	li {
		display: inline-block;
	}
	/*
		a {
			display: inline-block;
			width: 24px;
			height: 24px;
			border: 1px solid white;
			font-family: $primary-font;
		}
		*/

	img {
		width: 8px;
		position: relative;
		top: -2px;
	}

	a:hover,
	.active a {
		background: $blue;
		border-radius: 30px;
		color: white;
	}
}

.subfilter-list {
	display: none;
	padding: 4px 10px;
	background-color: $light-gray;
}

.subcategory-minus {
	display: none;
}

.subfilter-open {
	.subcategory-plus {
		display: none;
	}

	.subfilter-list {
		display: block;
	}

	.subcategory-minus {
		display: block;
		position: relative;
		top: 8px;
	}
}

li.active > a {
	/* background: white;
	color:#fff; */
	.visible-active {
		display: inline !important;
	}
	.hidden-active {
		display: none !important;
	}
}

.color-checkbox {
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 3px;
}

.filters {
	.filter-tag {
		span {
			text-transform: capitalize;
			padding: 0 10px;
		}

		a {
			text-transform: capitalize;
		}

		li.active:not(.color) {
			background: #2289d3;
			border-radius: 12.5px;

			> a > span {
				color: white;
			}
		}

		padding: 5px 10px;
		position: relative;

		@media screen and (min-width: 40em) and (max-width: 63.9375em) {
			min-width: 33%;
		}

		.scroll-view {
			height: 220px;
		}
	}

	.filter-sublist {
		> li > a {
			padding-left: 12px;
		}

		> li > a {
			font-weight: bold;
			text-transform: uppercase;

			> li {
				text-transform: capitalize;
			}
		}

		> li > ul {
			display: none;
		}

		> li.open > ul {
			display: block;
		}
	}

	.filter-name {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-transform: capitalize;
		font-size: 16px;
		padding: 0 10px;
		margin: 0;
		line-height: 30px;
		cursor: pointer;
		width: fit-content;
		font-weight: 100;
		width: 100%;
		border-radius: 19px;
		background: $light-gray;
		border: 2px solid $line-color;

		&:hover,
		&:active {
			border-color: $blue;
		}
	}

	&.open {
		.filter-name {
			border: 1px solid $black !important;
			span.fa {
				filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
				transform: rotate(-180deg);
			}
		}
	}

	.filter-dropdown {
		display: none;
		position: absolute;
		top: 45px;
		left: 10px;
		right: 10px;
		z-index: 1;
		padding: 0px 8px 8px 8px;
		z-index: 30;
		min-width: 200px;
		background: $light-gray;
		border-radius: 11px;

		.btn {
			display: block;
			margin-top: 5px;
			background-color: $blue;
		}
	}

	.fa-check:before {
		content: "\f00c";
		color: #ffffffe8;
		text-shadow: 0 0 1px #797878;
	}
}

.visible-active {
	display: none !important;
}
.hidden-active {
	display: inline;
}

.apply-filter {
	text-align: center;
	color: $blue;
	text-transform: uppercase;
	border-top: 2px solid #c1c1c1;
	padding-top: 13px;
	cursor: pointer;
}

.apply-size-filter {
	text-align: center;
	color: $blue;
	text-transform: uppercase;
	border-top: 2px solid #c1c1c1;
	padding-top: 13px;
	cursor: pointer;
}

.current-filters-container {
	@include breakpoint(small down) {
		display: flex !important;
		flex-wrap: wrap;
		margin-top: 30px;

		span {
			width: 100%;
		}
	}
}

.current-filter {
	background: $blue;
	border-radius: 12.5px;
	color: white;
	padding: 2px 10px 2px 0;
	margin: 0 5px;
	text-transform: capitalize;
	@include breakpoint(small down) {
		margin: 4px 1px;
	}

	&.all {
		color: $black;
		border: 1px solid $blue;
		float: right;
		background: $white;
		@include breakpoint(small down) {
			display: inline;
			float: none;
		}
	}
}

.grid-layout {
	&.catalog {
		@include breakpoint(small down) {
			margin-top: 47px;
		}
	}
}

.no-products {
	text-align: center;
	margin: 6vw 0 6vw;
	padding: 50px;
	@include breakpoint(medium only) {
		margin: 13vw 0 12vw;
		padding: 0px;
	}
	@include breakpoint(small down) {
		margin: 6vw 0 6vw;
		padding: 0px;
	}
}

.search-keyword {
	font-size: 35px;
}

.single-product-image {
	width: 100%;
}