// color
$black: #2F2D32;
$gray: #A3A3A3;
$light-gray: #F4F4F4;
$orange: #FCC68D;
$orange-hover: #f9b874;
$dark-orange: #C47018;
$light-blue: #EEF2F4;
$light-blue-hover: #E2E8EC;
$red: #DE252A;
$green: #16a228;
$blue: #2289d3;
$blue-hover: #0D6CB1;
$white: #ffffff;
$line-color: #cccccc;
$border: #e3e6ea;
$paypal-bg: #eeeeee;
$paypal-bg-hover: #e8e7e7;
$saldi: #07d6ea;

// fonts
$primary-font: 'Nunito Sans', Arial, Helvetica, sans-serif;
$secondary-font: 'Amatic SC', cursive;

// z-indexes
$header-z: 1000;
$messagebox-z: 2000;

// Slick slider
$slick-font-path: '/skins/kidsfashion/fonts/slick/';
$slick-loader-path: '/skins/kidsfashion/images/';