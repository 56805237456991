#designers-container {
    padding-top: 10px;

    h2 {
        border-bottom: 1px solid $black;
        margin-bottom: 7px;
    }

    a.card {
        &:hover, &:active, &:focus {
            background-color: #2289d3;
            border-radius: 50%;
            color: #fff;
        }
    }
}

.designer-letters {
    justify-content: space-around;
    padding: 20px 0;
}

.letters-div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sticky-letters {
    position: sticky;
    top: 57px;
    background: $white;
    @include breakpoint(medium down) {
        top: 102px;
    }
    @include breakpoint(small down) {
        top: 106px;
    }
}

.scrolling-wrapper {
    @media screen and (max-width: 63.9em) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
    }
}

.card {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
}

.bg-letters-active {
    background-color: $blue;
    border-radius: 50%;
    color: #fff;

    &.catalog-filter {
        border-radius: 12.5px;
    }
}

.letter-box {
    @media screen and (max-width: 63.9em) {
        width: 100%;
        padding: 20px 20px 0 20px;
    }
}

/*
.card {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 4.39rem;
  height: 4.4rem;
  margin: .4rem 0 .6rem;
  display: inline-block;
  vertical-align: middle;
  color: #222;
  text-decoration: none;
  text-align: center;
  line-height: 4.4rem;
  @media screen and (max-width: 63.9em) {
    width: 50px;
    text-align: center;
    flex: 0 0 auto;
    margin-right: 3px;
  }
}

.bg-letters-active{
  background-color: $blue;
  /*padding: 7px 13px;
  border-radius: 50%;
  width: 30px;
  color: $white;
  height: 30px;
  border-radius: .5rem;
  cursor: pointer;
}

/*
.letter-box{
  width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

*/
