.cms-wrapper {
  padding-top: 10px;

    p { margin-bottom: 10px; }

    ul, ol { 
      padding: 0 0 10px 20px; 
      &.accordion {
        @include breakpoint(medium down) { 
          padding-left: 0;
        }
      }
    }
}


.content-404 {

    h1 { font-size: 50px; }
}


// size table
/*
.size-table-page {

    table {
      border-collapse: collapse;
    }

    h3 {
      margin: 25px 0 5px;
      font-family: $primary-font;
    }

    th {
      background-color: $light-blue;
      text-align: left;
      padding: 5px 12px;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    td {
      padding: 4px 12px;
      font-size: 14px;
    }

    tr:nth-child(odd) {
      background-color: $light-gray;
    }

    .size-responsive-table {
      overflow-x: scroll;

        table { min-width: 600px; }
    }
}
*/

//size-table

.size-table {

  th{
    text-align: center;
  }

  td{
    text-align: center;
  }
  thead{
    border: none;
  }

  tbody{
    border: none;
  }
}

.light-bg-size-table{
  background-color: $orange;
}

.dark-bg-size-table{
  background-color: $orange-hover;
}

// dropshipping
.dropshipping-page {

    h1 {
      line-height: 1.2;
      text-align: center;
      font-size: 35px;
      max-width: 800px;
      margin: 30px auto;
    }

    h2 {
      margin-bottom: 5px;
      font-family: $primary-font;
      text-transform: uppercase;
      font-size: 20px;
    }

    .infographic { margin: 50px 0; }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      position: relative;
      padding: 10px 0 10px 35px;
      line-height: normal;

        &:before {
          content: '';
          background-image: url('/skins/current-skin/images/dropshipping-list.svg');
          width: 25px;
          height: 25px;
          left: 0;
          top: 7px;
          position: absolute;
        }
    }

    .dropshipping-box {
      background-color: $light-blue;
      text-align: center;
      padding: 20px 0 15px;
      margin-top: 15px;
      @include breakpoint(medium down) { margin-top: 30px; }

        img {
          width: 100px;
          margin-bottom: 15px;
        }

        p {
          font-family: $primary-font;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: -0.05em;
        }
    }

    .dropshipping-btn {
      display: block;
      background-color: $light-blue;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      padding: 5px 0 8px;
      margin-top: 10px;
      @include basic-transition;

        &:hover { background-color: #e0830e; }

        span {
          display: block;
          font-size: 11px;
          margin-top: -3px;
          color: white;
        }
    }

    .table-price {
      background-color: $light-blue;
      text-align: center;
      padding: 20px 20px 20px;
      margin-top: 20px;
      @include breakpoint(medium down) { margin-top: 30px; }

        h4 {
          text-transform: uppercase;
          letter-spacing: 0.05em;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          margin-top: 20px;
        }

        span {
          display: block;
          font-size: 46px;
          font-weight: bold;
          line-height: 46px;

            span {
              font-size: 20px;
              line-height: 22px;
              font-weight: normal;
            }
        }
    }

    .dropshipping-review {
      text-align: center;
      display: flex;
      margin-top: 20px;

        .review-image {
          width: 110px;
          height: 110px;
          border-radius: 55px;
          overflow: hidden;
          @include breakpoint(small down) {
            width: 80px;
            height: 80px;
            border-radius: 40px;
          }
        }

        p {
          text-align: left;
          margin-left: 25px;
          @include breakpoint(small down) {
            margin-left: 18px;
            font-size: 15px;
            line-height: 1.4;
          }
        }
    }
}

// chi siamo
.video-container {
  position: relative;
  padding-bottom: 51.25%;
  padding-top: 22px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    }
  }

  //faq

.main #widget-layout .col-12 #widget-content-body .tabpanel .faq .tab-content .tabpanel .panel-group .panel.panel-default{
  .card-title{
    font-size: 18px;
      a{
        color: $black;
      }
  }
  .card-header{
    background-color: $light-blue;
    padding: 0.65rem 1.25rem .35rem 1.25rem;
  }

}


.box-list-price{
  display: flex;
  justify-content: center;
  border-top: 1px solid $line-color;
}

.bg-light-blue-price{
  background-color: $light-blue;
  min-height: 652px;

  &:hover{
    background-color: $light-blue-hover;
  }
}

.img-plugin{
  width: 200px;
}



.brand-description-container {
  margin: 24px;
}

.brand-description-column {
  padding: 0 10px;  
}

.brand-description-inner {
  padding: 24px;
  background-color: $light-blue;
  border-radius: 8px;
}

.faq-section {
  margin-bottom: 16px;

  .faq {
    width: 100%;

    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px;
      background-color: $light-gray;
      border-bottom: 1px solid $gray;

      img.open {
        display: block;
      }
      img.close {
        display: none;
      }
    }

    .question {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
    }
    
    .answer {
      display: none;
      width: 100%;
      padding: 16px 20px 16px 8px;
      border-bottom: 1px solid $gray;
    }

    &:first-child .toggle {
      border-top: 1px solid $gray;
    }
  }

  .faq.open .toggle {
    img.open {
      display: none;
    }
    img.close {
      display: block;
    }
  }
}

ul li.dots {
  list-style-type: disc;
  margin-top: 10px;
}