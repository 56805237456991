.btn {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border: 0px;
  padding: 9px 17px;
  display: inline-block;
  text-align: center;
  @include basic-transition;
  font-size: 14px;
  color: $white;
  line-height: 1.5;
  cursor: pointer;

    &.btn-primary {
      background-color: $blue;
      border-radius: 20px;
        &:hover {
          background-color: $blue-hover;
        }
    }
    &.btn-secondary {
      color: $blue;
      border: 1px solid $blue;
      border-radius: 30px;
      //padding: 7px 24px 5px 24px;

        &:hover {
          color: $blue-hover;
        }

      &.checkout{
        border-radius: 4px;
        font-weight: 600;
        background-color: $paypal-bg;

        &:hover{
          background-color: $paypal-bg-hover;
        }

      }
    }
    &.btn-third{
      border:none;
      color: $blue;
      font-weight: 600;

      &.padding-left-right-0{
        padding: 17px 0px;
      }
    }

    &.btn-no-padding{
     padding: 0;
    }

    &.btn-black{
      border:none;
      color: $black;
    }

    &:disabled { 
      opacity: .3; 
    }

    &.btn-padding{
      padding: 9px 17px;
    }
    &.btn-bg{
      background-color: $light-blue;
    }

    &.btn-download{
      padding: 6px 12px;
      font-size: 13px;
      letter-spacing: 0;
      margin: 2px;
      color: $black;
      border: 1px solid $blue;
    }

    &.btn-disable{
      opacity: 0.3;
    }
}

.border-paypal.button{
  border: 1px solid $blue;
  border-radius: 4px;
}

//arrow

.arrow-link {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.arrow-link:after {
  content: '>';
  margin-left: 10px;
  right: -16px;
  top: 25px;
  font-size: 18px;
  color: $blue;
}

:after{
  box-sizing: border-box;
}

/*
.arrow-link {
  @include basic-transition;

    &:hover {
      img { transform: translateX(4px); }
    }
}
*/


// text input + label
.label-input {

    label {
      text-align: left;
      font-size: 12px;
      color: $black;
      letter-spacing: 0.05em;

      &#shop-error {
        color: $red;
        margin-top: 20px;
      }

      &.error {
        color: $red;
      }
    }

    input {
      border: none;
      box-shadow: none;
      border: 1px solid $gray;
      margin-bottom: 10px;
      padding-top: 6px;
      border-radius: 5px;
      &:focus {
        border-color: $blue;
        box-shadow: none;

          + label {
            color: $blue;
          }
      }
      &.error {
        border-color: $red !important;
        background-color: #faedeb;
      }
    }

    textarea, select { 
      border: none;
      box-shadow: none;
      border: 1px solid $gray;
      margin-bottom: 10px;
      padding-top: 6px;
      border-radius: 5px;
      &:focus {
        border-color: $blue;
        box-shadow: none;
      }
    }


    .i-number { margin: 5px 0; }
}


// checkbox
/*
.checkbox[type="checkbox"]:checked,
.checkbox[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}


.checkbox[type="checkbox"]:checked + label,
.checkbox[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  color: $black;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 15px;
}

.checkbox[type="checkbox"]:not(:checked) + label.is-invalid-label a {
  color: $red;
}


.checkbox[type="checkbox"]:checked + label:before,
.checkbox[type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 13px;
  height: 13px;
  border: 1px solid $black;
  background: #fff;
}

.checkbox.is-invalid-input[type="checkbox"]:not(:checked) + label:before {
  border-color: $red;
}

.checkbox[type="checkbox"]:checked + label:after,
.checkbox[type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 11px;
  height: 11px;
  background: $black;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: all 0.2s ease;
}

.checkbox[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.checkbox[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

*/

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label,
  & + label a {
    position: relative;
    padding: 0;
    margin: 5px 0;

      &.is-invalid-label,
      &.is-invalid-label a {
        color: $red;
      }
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: none;
    border: 1px solid $blue;
    position: relative;
    top: -2px;
  }

  &:hover + label:before {
    background: $blue;
  }

  /*&:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  */

  &:checked + label:before {
    background: $blue;
  }

  &:disabled + label {
    color: $blue;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: $white;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 8px;
    background: $white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 $white,
            4px 0 0 $white,
            4px -2px 0 $white,
            4px -4px 0 $white,
            4px -6px 0 $white,
            4px -8px 0 $white;
    transform: rotate(45deg);
  }

  &.error + label {
    color: $red;
  }
}


input[type=checkbox] + label {
  margin: 0;
  position: relative;
  cursor: pointer;
  float: left;
  font-size: 14px;
  color: $black;
  line-height: 1.5;
  margin-bottom: 20px;
  @include breakpoint(small down) {
    font-size:14px;
  }

    a {
      color: $black;
    }
}


// radio button
.radio {
  margin: 0.1rem 0;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      line-height: 24px;
      margin: 0;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 1px solid darken($black, 25%);
        display: inline-block;
        width: 1.7em;
        height: 1.6em;
        position: relative;
        top: 1px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $black;
          box-shadow: inset 0 0 0 4px $light-gray;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $black;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $light-gray;
          border-color: darken($light-gray, 25%);
          background: darken(white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

input.error:not(:focus), select.error:not(:focus) {
  border: 1px solid #cc4b37;
  background-color: #faedeb;
}

[type='submit'],
  [type='button'] {
    appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0px;
    cursor: pointer;
    background: none;
    padding: 10px 0px;
  }