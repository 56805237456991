// fonts
.nunito {
font-family: $primary-font;
}

.nunito-regular {
  font-weight: 400;
}

.nunito-semibold {
    font-weight: 600;
}
.nunito-bold {
    font-weight: 700;
}

.amatic-bold {
    font-weight: 700;
}

h1{
  font-size: 32px;
  margin: 0px;
}
h2{
  font-size: 28px;
}
h3{
    font-size: 20px;
    }

h4{
  font-size: 18px;
  font-weight: 500;
  }


// colors
.white { color: white; }
.orange { color: $orange; }
.light-blue { color: $light-blue; }
.blue { color: $blue;}

//Size

.px46 { font-size: 46px;}
.px20 { font-size: 20px;}
.px18 { font-size: 18px;}
.px16 { font-size: 16px;}
.px14 { font-size: 14px;}
.px13 { font-size: 13px;}


// space
.ls-005 { letter-spacing: 0.05em; }

// Transform
.upper { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }
.txt-center { text-align: center !important; }
.txt-right { text-align: right; }
.txt-left { text-align: left; }
.underline { text-decoration: underline; }
.weight-normal { font-weight: 400; }
.semi-bold {font-weight: 600 !important}
.line {text-decoration: line-through;}