// home slider
.slider-arrows {
  position: absolute;
  left: 0;
  bottom: 6px;
}

.slider-home {
  height: 570px;
  width: 100%;
  @include breakpoint(medium only) {
    height: 433px;
  }
  @include breakpoint(small down) {
    height: 211;
  }
}

.slider-home-arrow {
  background-color: white;
  padding: 24px 28px;
  width: 67px;
  cursor: pointer;
  @include breakpoint(small down) {
    padding: 18px 22px;
    width: 52px;
  }
}

.home-product-sliders {
  height: 435px;
  @include breakpoint(medium only) {
    height: 422px;
  }
  @include breakpoint(small down) {
    height: 321px;
  }
}

.right-image {
  @media screen and (min-width: 40em) {
    padding-left: 7px;
  }
}

.left-image {
    @media screen and (min-width: 40em) {
      padding-right: 7px;
    }
    @include breakpoint(medium down) {
      margin-bottom: 20px;
    }
  }

.text-on-image {
  position: absolute;
  bottom: 0px;
  left: 31px;
  font-family: $secondary-font;
  font-size: 65px;

  &.plus {

    display: flex;
    flex-direction: column;
    @media screen and (min-width: 40em) {
      bottom: 10px;
    };

    >span {
      font-family: $secondary-font;
      font-size: 65px;
    }
  }

}

.logo-menu-mobile {
  height: 86.36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#slider-arrow-left {
  padding: 24px 28px;
  width: 67px;
  cursor: pointer;
  position: absolute;
  left: -13px;
  top: 43%;
}

#slider-arrow-right {
  padding: 24px 28px;
  width: 67px;
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: 43%;
}

.mobile-menu__language {
  display: flex !important;
  justify-content: space-between;
}


// home box
.home-title {

  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.02em;
  text-align: center;
  color: black;

}

.home-box {
  cursor: pointer;

    a {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .home-box-img-container {
      height: 350px;
      overflow: hidden;
      margin-bottom: 12px;
      @media only screen and (max-width: 1200px) { height: 29vw; }
      @include breakpoint(small down) { height: 58vw; }
    }

    .home-box-img-container-small {
      height: 350px;
      overflow: hidden;
      margin-bottom: 12px;
      @media only screen and (max-width: 1200px) { height: 29vw; }
    }

    .home-box-img {
      height: 100%;
      @include background-center;
      @include basic-transition;
    }

    h3 {
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
    }

    &:hover {
      .link-arrow { transform: translateX(5px); }
      .home-box-img { transform: scale(1.05); }
    }
}

.home-box-margin {
  @include breakpoint(small down) { margin-top: 25px; }
}

.link-arrow {
  @include basic-transition;
  position: relative;
  top: -1px;
  margin-left: 5px;
}

.sales-icon {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  width: 46px;
  height: 46px;
  @include breakpoint(small down) {
    left: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
  }
}


// home registration
.home-registration {
  height: 524px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $light-blue;
  padding: 25px 30px;
  @include breakpoint(medium only) { display: none; }
  @include breakpoint(small down) { padding: 20px; }

    .home-registration-title {
      font-size: 30px;
      line-height: 41px;
      font-weight: bold;
      color: $blue;
      letter-spacing: 0.02em;
      @media only screen and (max-width: 1200px) {
        font-size: 22px;
      }

        span {
          display: block;
          font-size: 16px;
        }
    }

    .home-registration-subtitle {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        margin-top: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        width: 80%;
    }

    .home-registration-text {
      font-size: 18px;
      margin-top: 20px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
      width: 80%;

      &.link {
        margin: 0;
        padding: 0 20px;
      }

    }

    .home-registration-text-2 {
      font-size: 18px;
      margin-top: 5px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 5px;
      width: 80%;

      &.link {
        margin: 0;
        width: 100%;
        padding: 0 10px;
      }

    }

    h3 {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
    }

    .padd-right-3 { padding-right: 3px }
    .padd-left-3 { padding-left: 3px }

    hr {
      width: 100%;
      margin: 20px 0;
      border-top: 1px solid grey;
    }
}

.home-registration-list {
  margin-top: 15px;

    li {
      line-height: 33px;
      font-size: 18px;
      @media only screen and (max-width: 1200px) {
        font-size: 16px;
      }

      &::before {
        content: url(/skins/kidsfashion2018/images/pink-plus.svg);
        margin-right: 12px;
      }
    }
}


// home title + link
.home-title-link {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  padding-bottom: 5px;
  margin-bottom: 20px;

    span { margin-right: 5px; }
}


// slider logos
#slider-logos {

    img {
      padding: 0 15px;
      @include breakpoint(small down) { margin: 0 auto; }
    }
}


// blog
.blog-tag {
  background-color: $black;
  color: white;
  text-transform: uppercase;
  padding: 4px 10px 3px;
  display: inline-block;
  font-size: 12px;
  margin: 0 5px 5px 0;
  letter-spacing: 0.05em;
}

.home-article-img {
  @include background-center;

    &.first-article {
      height: 400px;
      @include breakpoint(medium down) { height: 70vw; }
    }
    &.second-article {
      height: 200px;
      @include breakpoint(medium down) { height: 35vw; }
      @include breakpoint(small down) { height: 70vw; }
    }
}

.home-article-txt {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint(small down) {
    height: auto;
    margin: 15px 0;
  }

    &.first-article {
      padding-bottom: 30px;
      @include breakpoint(medium only) { margin-top: 15px; }
      @include breakpoint(small down) { padding-bottom: 0; }

        h3 {
          font-size: 24px;
          @include breakpoint(small down) { font-size: 20px; }
        }
    }

    &.second-article {
      h3 {
        font-size: 18px;
        @include breakpoint(small down) { font-size: 20px; }
      }
    }

    h3 {
      line-height: normal;
      margin: 6px 0;
      @include breakpoint(small down) { margin: 2px 0; }
    }

    .arrow-link {
      @include breakpoint(small down) { margin-top: 5px; }
    }
}
//slider
.img-slider{
  display: inline-block !important;
  max-height: 570px;
  object-fit: cover;
  width:100%;
}

.home-filter {
  margin: 0 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.home-filter-div {
  position: relative;
  /*min-height: 476.26px;*/
  /*min-height: 451.26px;*/
  max-height: 380px;
  @media screen and (max-width: 39.9375em) {max-height: 255px;}
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {max-height: 325px;}
}

.img-grid {
  height: 270px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
    @include breakpoint(medium only) { 
      height:29vw;
    }
    @include breakpoint(small only) { 
      height:58vw;
    }
   &.square{
    height: 678px;

    @include breakpoint(medium only) { 
      height:47vw;
    }
    @include breakpoint(small only) { 
      height:94vw;
    }
   }
}

.new-arrivals-category{
  text-align: center;
  justify-content: center;
}

.title-text-centered{
  margin-top:40px;

  @include breakpoint(medium down) { 
    margin-top:0px;
  }
}

.title-img-grid{
  padding: 0 25px;
  @include breakpoint(medium down) { 
    padding: 0px;
  }
}