#cart-container {
  padding-top: 20px;

  .product-brand { text-transform: uppercase; }

  .product-sku {
    font-size: 12px;
    display: block;
  }

  .cart-details {
    position: relative;

    #remove-all-unavailable {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: $blue;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
  }
}

.border-right {
  border-right: 1px solid $black;
  @include breakpoint(small down) { border-right: none; }
}

.quare-btn {
  border: 1px solid $black;
  padding: 0 8px;
  margin-left: 2px;
  cursor: pointer;
  @include basic-transition;

    img { width: 20px; }

    &:hover {
      background-color: $light-gray;
    }
}

.product-total-wrapper {
  height: 100%;
  @include flex-center;

    .product-total {
      font-size: 30px;
      display: block;
      line-height: normal;
      @include breakpoint(medium down) { font-size: 22px; }
    }
}


.cart-update {
  @include breakpoint(small down) {
    max-width: 275px;
    margin: 0 auto;
  }
}

.product-detail {
  .product-image {
    overflow: hidden;
    border: 1px solid $border;
  }

  @include breakpoint(small down) { 
    text-align: center; 
  }
}

.product-total-wrapper {
  @include breakpoint(small down) { padding: 5px 0 30px; }
}

.cart-summary-detail {

    .cart-summary-detail-total {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .cart-summary-detail-total-eur {
      text-align: right;
    }

    hr {
      border: none;
      border-top: 1px solid $black;
      margin: 8px 0;
    }
}


.cart-summary-wrapper {
  background-color: $light-blue;
  padding: 20px 25px;
  @include breakpoint(small down) { margin-bottom: 25px; }
}


.padding-right-0 {
  padding-right: 0;
  @include breakpoint(small down) { padding-right: .625rem; }
}

.padding-left-2 {
  padding-left: 2px;
  @include breakpoint(small down) { padding-left: .625rem; }
}

.btn-coupon {
  font-size: 13px;
  @include breakpoint(small down) { margin: -6px 0 20px; }
}

.empty-cart {
  text-align: right;

  @include breakpoint(medium down) { 
    text-align: left;
  }
}

.delivery-time {
  position: relative;
  font-weight: 700;
  font-size: 16px;
}

.product-image .availability-sticker {
  font-size: 8px;
}