#support-trigger {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  @include breakpoint(small down) {
    top: auto;
    bottom: 0;
    transform: none;
  }

    img {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
}


#support-popup {
  position: fixed;
  z-index: 10;
  background-color: white;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 260px;
  border: 1px solid $light-blue;
  display: none;
  @include breakpoint(small down) {
    top: auto;
    bottom: 0;
    transform: none;
  }

    .support-popup-title {
      background-color: $light-blue;
      text-align: center;
      padding: 8px 0 6px;

        h4 { opacity: .6; }
    }

    #support-close {
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }

    .support-popup-content {
      padding: 20px 25px;

        hr {
          border: none;
          border-top: 1px solid $light-blue;
          margin: 10px 0 8px;
        }
    }
}